<template>
  <div id="wait-reward" class="container-fluid pb-5 position-relative">

    <div class="alert-info border-info rounded p-2 mb-2"><i class="fas fa-info-circle"></i> แสดงข้อมูลงวดที่ยังไม่ออกผลรางวัล</div>

    <div class="page-header mb-1">
      <div class="d-flex justify-content-between">
        <h4 class="mb-0 mr-2">รอผลเดิมพัน</h4>
        <span>
          <button v-if="role==='Agent'" type="button" class="btn btn-warning border-warning btn-sm py-0 mr-2" @click="reload(false)">รีเฟรช</button>
          <small>(รีเฟรชใน {{countDown}} วินาที)</small>
        </span>
      </div>
    </div>

    <ol v-if="!isLoading" class="breadcrumb px-2 py-1 m-0 mb-2">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div v-if="role==='Member'">
      <MemberTickets :accountId="accountId" :roundId="roundId" @Breadcrumbs="setBreadcrumbs" />
    </div>
    <div v-else>
      <div class="card">
        <table class="table table-bordered table-secondary mb-0">
          <thead>
            <tr>
              <th rowspan="2" colspan="2">ชนิดหวย</th>
              <th width="12%" rowspan="2">งวด</th>
              <th width="10%" rowspan="2" style="border-right: 2px solid #b3b7bb;">ยอดทั้งหมด</th>
              <th colspan="3" style="border-right: 2px solid #b3b7bb;">สมาชิก</th>
              <th colspan="3" style="border-right: 2px solid #b3b7bb;">{{agentName}}</th>
              <th colspan="3">บริษัท</th>
            </tr>
            <tr>
              <th width="6%" class="p-1">ส่งออก</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1" style="border-right: 2px solid #b3b7bb;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1" style="border-right: 2px solid #b3b7bb;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1">รวม</th>
            </tr>
          </thead>
          <tbody v-for="item in rounds" :key="item.round.roundId">
            <tr>
              <td width="60" class="p-0 py-1 text-center" style="cursor: pointer;" @click="showAccount(item)">
                <img :src="item.icon" height="32" class="mx-auto d-block">
              </td>
              <td class="p-0 px-1 text-primary" style="cursor: pointer;" @click="showAccount(item)">{{item.market.groupTitle}} ✦ {{item.market.marketTitle}}</td>
              <td class="text-center">{{item.round.roundDate}}</td>
              <td class="text-right px-1 text-success" style="border-right: 2px solid #b3b7bb;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger">{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-success">{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger" style="border-right: 2px solid #b3b7bb;">{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentAmount>0
                }, {
                  'text-danger': item.summary.agentAmount<0
                }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentCommission>0
                }, {
                  'text-danger': item.summary.agentCommission<0
                }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1" style="border-right: 2px solid #b3b7bb;"
                :class="[{
                  'text-success': item.summary.agentTotal>0
                }, {
                  'text-danger': item.summary.agentTotal<0
                }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyAmount>0
                }, {
                  'text-danger': item.summary.companyAmount<0
                }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyCommission>0
                }, {
                  'text-danger': item.summary.companyCommission<0
                }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyTotal>0
                }, {
                  'text-danger': item.summary.companyTotal<0
                }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
            </tr>
            <tr>
              <td colspan="13" class="p-0">
                <b-collapse :id="'collapse-'+item.round.roundId" :visible="showAccountRoundId===item.round.roundId">
                  <WaitRewardByAccount
                    :data="data"
                    :roundId="item.round.roundId"
                    :showName="showName"
                    @toggleAccount="getReport"
                    @toggleMember="toggleMemberAccount"
                    class="p-2" />
                </b-collapse>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="13" class="text-center alert-warning">กรุณารอซักครู่...</td>
            </tr>
          </tbody>
          <tbody v-if="!rounds.length && !isLoading">
            <tr>
              <td colspan="13" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="rounds.length">
        <div class="d-flex justify-content-between mb-1">
          <strong>รวมทั้งหมด</strong>
        </div>

        <ol class="breadcrumb px-2 py-1 m-0 mb-2">
          <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
            <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
            <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
          </li>
        </ol>

        <div class="card">
          <table class="table-wait-account table table-bordered table-secondary mb-0">
            <thead>
              <tr>
                <th rowspan="2">ชื่อใช้งาน
                  <b-form-checkbox
                    v-model="showName"
                    :value="true"
                    :unchecked-value="false"
                    class="mt-2 font-weight-normal"
                  >
                    แสดงชื่อ
                  </b-form-checkbox>
                </th>
                <th width="12%" rowspan="2">ระดับ</th>
                <th width="10%" rowspan="2" style="border-right: 2px solid #b3b7bb;">ยอดทั้งหมด</th>
                <th colspan="3" style="border-right: 2px solid #b3b7bb;">สมาชิก</th>
                <th colspan="3" style="border-right: 2px solid #b3b7bb;">{{agentName}}</th>
                <th colspan="3">บริษัท</th>
              </tr>
              <tr>
                <th width="6%" class="p-1">ส่งออก</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1" style="border-right: 2px solid #b3b7bb;">รวม</th>
                <th width="6%" class="p-1">ถือหุ้น</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1" style="border-right: 2px solid #b3b7bb;">รวม</th>
                <th width="6%" class="p-1">ถือหุ้น</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1">รวม</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in accounts" :key="item.agent.childAccountId">
                <td class="p-0 px-1 text-center">
                  <a v-if="item.agent.role==='Agent'" href="#" @click="toggleChildAccount(item.agent.childAccountId)">{{ item.agent.username }}</a>
                  <a v-else href="#" @click="toggleMemberAccount(item.agent.memberAccountId, null)">{{ item.agent.username }}</a>
                  <small v-if="showName" class="text-secondary ml-1">({{item.agent.firstName}})</small>
                </td>
                <td class="text-center">
                  <AccountRole :role="item.agent.role" />
                </td>
                <td class="text-right px-1 text-success" style="border-right: 2px solid #b3b7bb;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-danger">{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-success">{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-danger" style="border-right: 2px solid #b3b7bb;">{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.agentAmount>0
                  }, {
                    'text-danger': item.summary.agentAmount<0
                  }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.agentCommission>0
                  }, {
                    'text-danger': item.summary.agentCommission<0
                  }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1" style="border-right: 2px solid #b3b7bb;"
                  :class="[{
                    'text-success': item.summary.agentTotal>0
                  }, {
                    'text-danger': item.summary.agentTotal<0
                  }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyAmount>0
                  }, {
                    'text-danger': item.summary.companyAmount<0
                  }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyCommission>0
                  }, {
                    'text-danger': item.summary.companyCommission<0
                  }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyTotal>0
                  }, {
                    'text-danger': item.summary.companyTotal<0
                  }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" class="text-center">รวม</th>
                <td class="text-right px-1 text-success" style="border-right: 2px solid #b3b7bb;">{{ summary.amount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-danger">{{ summary.memberAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-success">{{ summary.memberCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1 text-danger" style="border-right: 2px solid #b3b7bb;">{{ summary.memberTotal | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': summary.agentAmount>0
                  }, {
                    'text-danger': summary.agentAmount<0
                  }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': summary.agentCommission>0
                  }, {
                    'text-danger': summary.agentCommission<0
                  }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1" style="border-right: 2px solid #b3b7bb;"
                  :class="[{
                    'text-success': summary.agentTotal>0
                  }, {
                    'text-danger': summary.agentTotal<0
                  }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyAmount>0
                  }, {
                    'text-danger': summary.companyAmount<0
                  }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyCommission>0
                  }, {
                    'text-danger': summary.companyCommission<0
                  }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
                <td class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyTotal>0
                  }, {
                    'text-danger': summary.companyTotal<0
                  }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="80"
      :width="80"
      color="#87CC35"
    />
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import WaitRewardByAccount from './components/WaitRewardByAccount'
import MemberTickets from './components/MemberTickets'
import cAlert from '@/helpers/alert'

export default {
  name: 'WaitReward',
  components: {
    WaitRewardByAccount,
    MemberTickets
  },
  data() {
    return {
      data: null,
      isLoading: false,
      showName: false,
      showAccountRoundId: null,
      Breadcrumbs: [],
      role: 'Agent',
      accountId: null,
      roundId: null,
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    rounds() {
      if(!this.data)
        return []

      const groups = _.groupBy(this.data.reports, (report)=>{
        return report.round.roundId
      })

      const rounds = []
      for(const roundId in groups) {
        const summary = groups[roundId].reduce((total, item)=>{
          total.amount += item.amount
          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberTotal += item.memberTotal
          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentTotal += item.agentTotal
          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyTotal += item.companyTotal
          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyTotal: 0
        })

        const market = this.$store.state.markets.find((m)=>{
          return groups[roundId][0].market?.marketId === m._id
        })

        rounds.push({
          round: groups[roundId][0].round,
          market: groups[roundId][0].market,
          summary: summary,
          icon: market?.imageIcon
        })
      }

      return _.orderBy(rounds, ['round.close'], ['asc'])
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data.reports.map((report)=>{

        const child = this.data.childs.find((child)=>{
          return child.memberAccountId === report._id.accountId
        })

        const account = this.data.accounts.find((account)=>{
          return account.childAccountId === child.childAccountId
        })

        return {
          ...report,
          agent: account
        }
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.agent.childAccountId
      })

      const accounts = []
      for(const accountId in groups) {

        const summary = groups[accountId].reduce((total, item)=>{
          total.amount += item.amount
          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberTotal += item.memberTotal
          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentTotal += item.agentTotal
          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyTotal += item.companyTotal
          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyTotal: 0
        })

        accounts.push({
          agent: groups[accountId][0].agent,
          summary: summary
        })
      }

      return _.orderBy(accounts, ['agent.username'], ['asc'])
    },
    summary() {
      return this.accounts.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberTotal += item.summary.memberTotal
        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentTotal += item.summary.agentTotal
        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyTotal += item.summary.companyTotal
        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyTotal: 0
      })
    }
  },
  methods: {
    getReport(accountId, silent=false) {
      this.role = 'Agent'

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      ReportService.getWaitReward(accountId, true)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.Breadcrumbs = response.data.agent.parents
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    showAccount(item) {
      if(this.showAccountRoundId === item.round.roundId){
        this.showAccountRoundId = null
      }else{
        this.showAccountRoundId = item.round.roundId
      }
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.getReport(accountId)
    },
    toggleMemberAccount(accountId, roundId) {
      this.role = 'Member'
      this.accountId = accountId
      this.roundId = roundId
      clearInterval(this.refreshInterval)
    },
    setBreadcrumbs(breadcrumbs) {
      this.Breadcrumbs = breadcrumbs
    },
    reload(silent=false) {
      this.getReport(this.accountId, silent)
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.reload(true)
        }
      }, 1000)
    }
  },
  mounted() {
    this.getReport(null)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 90%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 90%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-wait-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
